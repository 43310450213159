import MD5 from 'crypto-js/md5';
const boldFont = 'font-weight: bolder';
export default function ProxyDocument(whiteList, head, microfrontendName, configJson) {
  return new Proxy(document, {
    get(target, prop) {
      if (prop === 'head') {
        return head;
      }
      const value = Reflect.get(target, prop);
      const custom = whiteList?.[prop]?.get;

      /**
       * @example
       * document.title
       * document.createElement('div')
       */
      return typeof value === 'function' ? custom || value.bind(document) : custom || value;
    },

    set(target, prop, value) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`%c Are you sure you want to set value of document.${prop}?`, boldFont);
      }
      return whiteList?.[prop]?.set || Reflect.set(target, prop, value);
    },
  });
}

const globalCssVaribaleStyles = {};

const validStyleNode = node => {
  const ui5ThemeAttribute = node?.getAttribute('data-ui5-theme-properties')?.trim();
  const styleCode = node?.innerHTML?.trim();
  const ui5SystemVars = node?.getAttribute('data-ui5-system-css-vars');
  const ui5FontFace = node?.getAttribute('data-ui5-font-face');
  const ui5FontFaceOverride = node?.getAttribute('data-ui5-font-face-override');
  const validCssVarContent =
    styleCode?.length > 0 &&
    (styleCode?.startsWith(':root') ||
      styleCode?.startsWith('@font-face') ||
      styleCode?.indexOf('--_ui5_') >= 0);
  return (
    node?.nodeName === 'STYLE' &&
    validCssVarContent &&
    (ui5ThemeAttribute === '@ui5/webcomponents-theme-base' ||
      ui5ThemeAttribute === '@ui5/webcomponents-fiori' ||
      ui5ThemeAttribute === '@ui5/webcomponents' ||
      ui5SystemVars !== null ||
      ui5FontFace !== null ||
      ui5FontFaceOverride !== null)
  );
};

export const appendChildFake = (node, microfrontendName, target, head) => {
  if (validStyleNode(node)) {
    const md5Key = MD5(node?.innerHTML);
    if (!globalCssVaribaleStyles[md5Key]) {
      globalCssVaribaleStyles[md5Key] = node;
      node?.setAttribute('data-carried-from-mfe', microfrontendName);
      document.head.appendChild(node);
    } else {
      const previousSameNode = globalCssVaribaleStyles[md5Key];
      document.head.removeChild(previousSameNode);
      document.head.appendChild(previousSameNode);
    }
  } else {
    head.appendChild.apply(target, [node]);
  }
};

const validQuerySelector = selector => {
  return selector
    ?.trim()
    .startsWith('style[data-ui5-theme-properties=@ui5/webcomponents-theme-base]');
};

export function ProxyHead(whiteList, head, microfrontendName, rawDocumentHead, configJson = null) {
  return new Proxy(head, {
    get(target, prop) {
      if (prop === 'head') {
        return head;
      }
      const value = Reflect.get(target, prop);
      const custom = whiteList?.[prop]?.get;

      if (prop === 'appendChild') {
        return node => {
          return appendChildFake(node, microfrontendName, target, head);
        };
      }

      if (prop === 'querySelector') {
        return selector => {
          if (validQuerySelector(selector)) {
            document.head.querySelector(selector);
          } else {
            head.querySelector.apply(target, [selector]);
          }
        };
      }

      /**
       * @example
       * document.title
       * document.createElement('div')
       */
      return typeof value === 'function' ? custom || value.bind(head) : custom || value;
    },

    set(target, prop, value) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`%c Are you sure you want to set value of head.${prop}?`, boldFont);
      }
      return whiteList?.[prop]?.set || Reflect.set(target, prop, value);
    },
  });
}

export function ProxyBody(whiteList, body) {
  return new Proxy(body, {
    get(target, prop) {
      if (prop === 'body') {
        return body;
      }
      const value = Reflect.get(target, prop);
      const custom = whiteList?.[prop]?.get;

      /**
       * @example
       * document.title
       * document.createElement('div')
       */
      return typeof value === 'function' ? custom || value.bind(body) : custom || value;
    },

    set(target, prop, value) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`%c Are you sure you want to set value of body.${prop}?`, boldFont);
      }
      return whiteList?.[prop]?.set || Reflect.set(target, prop, value);
    },
  });
}
