import classCallCheck from '@babel/runtime/helpers/esm/classCallCheck';
import createClass from '@babel/runtime/helpers/esm/createClass';
import toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';
import { getTargetValue, setCurrentRunningSandboxProxy } from './common.js';
import { createProxyWindow } from './window-proxy';
/**
 * fastest(at most time) unique array method
 * @see https://jsperf.com/array-filter-unique/30
 */

function uniq(array) {
  return array.filter(function filter(element) {
    /** return element in this ? false : (this[element] = true); */
    if (element in this) {
      return false;
    } else {
      this[element] = true;
      return true;
    }
  }, Object.create(null));
} // zone.js will overwrite Object.defineProperty

/*
 variables who are impossible to be overwrite need to be escaped from proxy sandbox for performance reasons
 */

const unscopables = {
  undefined: true,
  Array: true,
  Object: true,
  String: true,
  Boolean: true,
  Math: true,
  Number: true,
  Symbol: true,
  parseFloat: true,
  Float32Array: true,
};

// see https://github.com/facebook/create-react-app/blob/66bf7dfc43350249e2f09d138a20840dae8a0a4a/packages/react-error-overlay/src/index.js#L180
// for react hot reload
const variableWhiteListInDev =
  process.env.NODE_ENV === 'development' ? ['__REACT_ERROR_OVERLAY_GLOBAL_HOOK__'] : []; // who could escape the sandbox

const variableWhiteList = [
  // System.js used a indirect call with eval, which would make it scope escape to global
  // To make System.js works well, we write it back to global window temporary
  // see https://github.com/systemjs/systemjs/blob/457f5b7e8af6bd120a279540477552a07d5de086/src/evaluate.js#L106
  'System', // see https://github.com/systemjs/systemjs/blob/457f5b7e8af6bd120a279540477552a07d5de086/src/instantiate.js#L357
  '__cjsWrapper',
].concat(variableWhiteListInDev);

// This is used for webpack, webpack will generate Jsonp
const variableWhiteListRegExp = [/^webpack/];

let sandboxCount = 0;

export const getWebpackPathPrefix = (configJson, microfrontendName) => {
  if (!configJson) {
    return undefined;
  }
  const targetConfig = configJson?.components?.find(
    item => item?.config?.app === microfrontendName,
  );
  let cdnPrefix = targetConfig?.location;
  if (cdnPrefix?.endsWith('/') === false) {
    cdnPrefix += '/';
  }
  if (!cdnPrefix || cdnPrefix === '') {
    //localhost development mode
    const { protocol, host } = window.location;
    const index = host.indexOf(':');
    const ip = index >= 0 ? host.slice(0, index) : host;

    cdnPrefix = `${protocol}//${ip}:${targetConfig?.port}/`;
  }
  return cdnPrefix;
};

export class IntersectionObserverProxy extends window.IntersectionObserver {
  constructor(callback, options) {
    if (typeof options === 'object' && options.root) {
      if (options.root instanceof Document) {
        options.root = window.document;
      }
    }
    super(callback, options);
  }

  observe(target) {
    if (target instanceof Document) {
      target = window.document;
    }
    super.observe(target);
  }
}
export class MutationObserverProxy extends MutationObserver {
  observe(target, options) {
    if (target instanceof Document) {
      target = window.document;
    }
    return super.observe(target, options);
  }
}

// export class TreeWalkerProxy extends TreeWalker {

//   constructor () {
//     return super()
//   }

//   // override set currentNode
//   set currentNode (node) {
//     let tnode = node;
//     if (tnode instanceof Document) {
//       tnode = document;
//     }
//     super.currentNode = tnode;
//   }
// }

export const keepTargetDescriptor = (rawWindow, p, target, value) => {
  const descriptor = Object.getOwnPropertyDescriptor(rawWindow, p);
  const writable = descriptor.writable,
    configurable = descriptor.configurable,
    enumerable = descriptor.enumerable;

  if (writable) {
    Object.defineProperty(target, p, {
      configurable: configurable,
      enumerable: enumerable,
      writable: writable,
      value: value,
    });
  }
};

export const doExceptionForEvent = (p, target, value) => {
  try {
    // window.event is an exceptional case
    if (p !== 'event') {
      target[p] = value;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateRawWindow = (rawWindow, p, value) => {
  if (
    typeof p === 'string' &&
    variableWhiteListRegExp.find(regexp => regexp.test(p)) &&
    !rawWindow.hasOwnProperty(p)
  ) {
    // @ts-ignore
    rawWindow[p] = value;
    return;
  }
};

const getRawValue = (p, propertiesWithGetter, rawWindow, target) => {
  let value = null;
  try {
    value = propertiesWithGetter.has(p) ? rawWindow[p] : p in target ? target[p] : rawWindow[p];
  } catch (error) {
    console.error(error);
  }
  return value;
};

export const getMockSafariTop = (rawWindow, proxy, p) => {
  if (process.env.NODE_ENV === 'test') {
    if (rawWindow === rawWindow.parent) {
      return proxy;
    }
    return rawWindow[p];
  }
};

export const getParent = (rawWindow, proxy, p) => {
  if (rawWindow === rawWindow.parent) {
    return proxy;
  }
  return rawWindow[p];
};

const initializeProxy = ({
  fakeWindow,
  _this,
  rawWindow,
  configJson,
  updatedValueSet,
  microfrontendName,
  name,
  myDocument,
  propertiesWithGetter,
  descriptorTargetMap,
  hasOwnProp,
}) => {
  const proxy = new Proxy(fakeWindow, {
    set: function set(target, p, value) {
      if (_this.sandboxRunning) {
        updateRawWindow(rawWindow, p, value);

        // We must kept its description while the property existed in rawWindow before
        if (!target.hasOwnProperty(p) && rawWindow.hasOwnProperty(p)) {
          keepTargetDescriptor(rawWindow, p, target, value);
        } else {
          // @ts-ignore
          doExceptionForEvent(p, target, value);
        }

        if (variableWhiteList.indexOf(p) !== -1) {
          // @ts-ignore
          rawWindow[p] = value;
        }

        updatedValueSet.add(p);
        _this.latestSetProp = p;
      } else if (process.env.NODE_ENV === 'development') {
        // while in strict-mode，Proxy's handler.set will return false and throw TypeError，we should catch and ignore those errors while sandbox not running
        console.warn(
          'You are trying to Set Prop to window. Prop: -> ',
          p,
          ' while sandbox not running ',
          name,
        );
      }
      return true;
    },
    get: function get(target, p) {
      if (typeof p === 'string' && variableWhiteListRegExp.find(regexp => regexp.test(p))) {
        return rawWindow[p];
      }

      switch (p) {
        case '__webpack_public_path__':
          return getWebpackPathPrefix(configJson, microfrontendName);
        case 'IntersectionObserver':
          return IntersectionObserverProxy;
        case 'window':
        case 'self':
        case 'globalThis':
          return proxy;
        case 'nativeWindow':
          return window;
        case 'nativeDocument':
          return document;
        case 'document':
          if (myDocument) {
            const targetConfig = configJson?.components?.find(
              item => item?.config?.app === microfrontendName,
            );
            if (targetConfig?.config?.shadow !== false) {
              return myDocument;
            }
          }
          return document;
        case 'testprop':
          console.log('testprop');
          break;
        case 'top':
        case 'parent':
          return getParent(rawWindow, proxy, p);
        case 'mockTop':
        case 'mockSafariTop':
          return getMockSafariTop(rawWindow, proxy, p);
        case 'hasOwnProperty':
          // proxy.hasOwnProperty would invoke getter firstly, then its value represented as rawWindow.hasOwnProperty
          return hasOwnProp;
        case 'eval':
          // mark the symbol to document while accessing as document.createElement could know is invoked by which sandbox for dynamic append patcher
          setCurrentRunningSandboxProxy(proxy); // if you have any other good ideas
          // remove the mark in next tick, thus we can identify whether it in micro app or not
          // this approach is just a workaround, it could not cover all complex cases, such as the micro app runs in the same task context with master in some case

          Promise.resolve().then(function () {
            return setCurrentRunningSandboxProxy(null);
          });
          if (p === 'document') {
            return document;
          } else if (p === 'eval') {
            // eslint-disable-next-line no-eval
            return eval;
            // no default
          }
          break;
        case 'MutationObserver':
          return MutationObserverProxy;
        default:
          break;
      }

      if (p === Symbol.unscopables) {
        // avoid who using window.window or window.self to escape the sandbox environment to touch the really window
        return unscopables;
      }

      return getTargetValue(rawWindow, getRawValue(p, propertiesWithGetter, rawWindow, target));
    },
    // trap in operator
    // see https://github.com/styled-components/styled-components/blob/master/packages/styled-components/src/constants.js#L12
    has: function has(target, p) {
      return p in unscopables || p in target || p in rawWindow;
    },
    getOwnPropertyDescriptor: function getOwnPropertyDescriptor(target, p) {
      /*
       as the descriptor of top/self/window/mockTop in raw window are configurable but not in proxy target, 
       we need to get it from target to avoid TypeError
       see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/handler/getOwnPropertyDescriptor
       > A property cannot be reported as non-configurable, if it does not exists as an own property of the target object or 
       if it exists as a configurable own property of the target object.
       */
      if (target.hasOwnProperty(p)) {
        const descriptor = Object.getOwnPropertyDescriptor(target, p);
        descriptorTargetMap.set(p, 'target');
        return descriptor;
      }

      if (rawWindow.hasOwnProperty(p)) {
        const _descriptor = Object.getOwnPropertyDescriptor(rawWindow, p);

        descriptorTargetMap.set(p, 'rawWindow'); // A property cannot be reported as non-configurable, if it does not exists as an own property of the target object

        if (_descriptor && !_descriptor.configurable) {
          _descriptor.configurable = true;
        }

        return _descriptor;
      }

      return undefined;
    },
    // trap to support iterator with sandbox
    ownKeys: function ownKeys(target) {
      const keys = uniq(Reflect.ownKeys(rawWindow).concat(Reflect.ownKeys(target)));
      return keys;
    },
    defineProperty: function defineProperty(target, p, attributes) {
      const from = descriptorTargetMap.get(p);
      /*
       Descriptor must be defined to native window while it comes from native window via Object.getOwnPropertyDescriptor(window, p),
       otherwise it would cause a TypeError with illegal invocation.
       */

      switch (from) {
        case 'rawWindow':
          return Reflect.defineProperty(rawWindow, p, attributes);

        default:
          return Reflect.defineProperty(target, p, attributes);
      }
    },
    deleteProperty: function deleteProperty(target, p) {
      if (target.hasOwnProperty(p)) {
        // @ts-ignore
        delete target[p];
        updatedValueSet.delete(p);
      }

      return true;
    },
  });
  return proxy;
};

const ProxySandbox = (function () {
  function ProxySandbox(name, myDocument, microfrontendName, configJson = null) {
    const _this = this;
    classCallCheck(this, ProxySandbox);

    /** window 值变更记录 */
    this.updatedValueSet = new Set();
    this.sandboxRunning = true;
    this.latestSetProp = null;
    this.name = name;
    this.type = 'Proxy';
    const updatedValueSet = this.updatedValueSet;
    const rawWindow = window;

    const _createFakeWindow = createProxyWindow(rawWindow);
    const fakeWindow = _createFakeWindow.proxyWindow;
    const propertiesWithGetter = _createFakeWindow.propertiesWithGetter;

    const descriptorTargetMap = new Map();

    const hasOwnProp = function hasOwnProp(key) {
      return fakeWindow.hasOwnProperty(key) || rawWindow.hasOwnProperty(key);
    };

    this.proxy = initializeProxy({
      fakeWindow,
      _this,
      rawWindow,
      configJson,
      updatedValueSet,
      microfrontendName,
      name,
      myDocument,
      propertiesWithGetter,
      descriptorTargetMap,
      hasOwnProp,
    });
    sandboxCount++;
  }

  createClass(ProxySandbox, [
    {
      key: 'active',
      value: function active() {
        if (!this.sandboxRunning) {
          sandboxCount++;
        }
        this.sandboxRunning = true;
      },
    },
    {
      key: 'inactive',
      value: function inactive() {
        const _this2 = this;

        if (process.env.NODE_ENV === 'development') {
          console.info(
            this.name,
            ' modified global properties restore...',
            toConsumableArray(this.updatedValueSet.keys()),
          );
        }

        if (--sandboxCount === 0) {
          variableWhiteList.forEach(function (p) {
            if (_this2.proxy.hasOwnProperty(p)) {
              // @ts-ignore
              delete window[p];
            }
          });
        }

        this.sandboxRunning = false;
      },
    },
  ]);

  return ProxySandbox;
})();

export { ProxySandbox as default };
