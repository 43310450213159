import { registFragment } from './proxies/microfrontend-helper';
import { generateUniqueDict } from './utils';
const microfrontendRegist = new Map();
const uniqueDict = generateUniqueDict();
const Suffix = '_Fragment';
let configJson = null;
const register = (name, configuration) => {
  const fragment = uniqueDict.getItem(name + Suffix);
  fragment.configuration = configuration;
};
const getFragment = name => {
  const fragment = uniqueDict.getItem(name + Suffix);
  return fragment && fragment.configuration;
};
const unmountFragment = (name, ...rest) => {
  const func = uniqueDict.findFuncInConfiguration(name + Suffix, 'unmount');
  if (func) {
    func.apply(null, rest);
  }
};

const getFragmentsWithTag = (tag, config = configJson) => {
  return getFragmentsDetailWithTag(tag, config).map(item => item.register);
};

const findFragmentInComponentsAndAddToTags = (component, tag, tagFragments) => {
  component.config?.fragments?.forEach(fragment => {
    if (
      Array.isArray(fragment.section) &&
      fragment.section.length > 0 &&
      fragment.section.indexOf(tag) >= 0 &&
      fragment.name &&
      fragment.name !== ''
    ) {
      const fragmentName = fragment.name.slice(0, 1).toUpperCase() + fragment.name.slice(1);
      const registerFragment = getFragment(component.config.app + fragmentName);
      if (registerFragment) {
        tagFragments.push({
          register: registerFragment,
          component,
          name: component.config.app,
        });
      } else {
        console.warn(
          `Fragment with name ${
            component.config.app + fragmentName
          } is null or undfined. Original fragment.name is ${fragment.name}`,
        );
      }
    }
  });
};

const isValidComponentWithFragments = component => {
  return (
    component &&
    component.config &&
    Array.isArray(component.config.fragments) &&
    component.config.fragments.length > 0
  );
};

const getFragmentsDetailWithTag = (tag, config = configJson) => {
  if (!config) {
    console.error(`getFragmentsWithTag failed because tag => ${tag} config =>${config}`);
  }
  const { components } = config || {};
  if (!Array.isArray(components) || components.length <= 0) {
    console.error(
      `getFragmentsWithTag failed because tag => ${tag} config =>${config} config.components => ${components}`,
    );
  }
  const tagFragments = [];
  components?.forEach(component => {
    if (isValidComponentWithFragments(component)) {
      findFragmentInComponentsAndAddToTags(component, tag, tagFragments);
    }
  });
  return tagFragments;
};

const resolveTagFragments = (tag, config = configJson) => {
  if (!config) {
    console.error(`resolveTagFragments failed because tag => ${tag} config =>${config}`);
  }
  const { components } = config || {};
  if (!Array.isArray(components) || components.length <= 0) {
    console.error(
      `resolveTagFragments failed because tag => ${tag} config =>${config} config.components => ${components}`,
    );
  }

  const promiseAll = [];
  components?.forEach(component => {
    if (
      component &&
      component.config &&
      Array.isArray(component.config.fragments) &&
      component.config.fragments.length > 0
    ) {
      const findItem = component.config.fragments.find(fragment => {
        return (
          Array.isArray(fragment.section) &&
          fragment.section.length > 0 &&
          fragment.section.indexOf(tag) >= 0 &&
          fragment.name &&
          fragment.name !== ''
        );
      });
      if (findItem) {
        promiseAll.push(
          registFragment(
            component?.location || config?.config?.cdn + ':' + component?.port,
            component?.config?.app,
            config,
          ),
        );
      }
    }
  });
  return promiseAll;
};

const removeAllChildren = container => {
  const children = container?.children;
  if (children && children.length > 0) {
    for (let i = 0; i < children.length; i++) {
      container.shadowRoot?.removeChild(children[i]);
    }
  }
};

const renderFragments = ({
  microfrontendName,
  container,
  fragmentsDetails,
  renderFragment,
  useShadowRoot,
}) => {
  const renderMap = {};
  if (
    !container ||
    !(container instanceof HTMLElement) ||
    !Array.isArray(fragmentsDetails) ||
    fragmentsDetails.length <= 0
  ) {
    console.error(
      `FragmentManager render fragments => ${fragmentsDetails} failed with container => ${container}`,
    );
  }
  const useShadow = useShadowRoot === false ? false : true;
  if (!container.shadowRoot && useShadow) {
    container.attachShadow({ mode: 'open' });
  }
  const shadowRoot = useShadow ? container.shadowRoot : container;
  fragmentsDetails.forEach(fragmentDetail => {
    const { microfrontendHead, microfrontendBody } = getMicrofrontendFragment({
      microfrontendName: fragmentDetail.name,
    });
    const fragmentContainer = document.createElement('div');
    microfrontendBody.appendChild(fragmentContainer);
    renderFragment(fragmentContainer, fragmentDetail.register, fragmentDetail);

    renderMap[fragmentDetail.name] = {
      fragmentContainer,
      fragmentDetail,
      microfrontendHead,
      microfrontendBody,
    };
    shadowRoot.appendChild(microfrontendHead);
    shadowRoot.appendChild(microfrontendBody);
  });
  return renderMap;
};

const unmountFragments = ({ container, renderMap }) => {
  if (!container) {
    return;
  }
  Object.keys(renderMap).forEach(key => {
    const fragamentRegist = renderMap[key];
    const { fragmentContainer, fragmentDetail, microfrontendBody } = fragamentRegist;
    fragmentDetail?.register?.unmount(fragmentContainer);
    removeAllChildren(microfrontendBody);
  });
  removeAllChildren(container.shadowRoot);
};

const registerMicrofrontendFragments = ({ microfrontendName, regist }) => {
  microfrontendRegist[microfrontendName] = regist;
};

const cloneFragmentRegistry = fragmentRegistry => {
  const { microfrontendHead, microfrontendBody } = fragmentRegistry;
  const microfrontendHeadCopy = microfrontendHead.cloneNode(true);
  const microfrontendBodyCopy = microfrontendBody.cloneNode(true);
  return {
    ...fragmentRegistry,
    microfrontendHead: microfrontendHeadCopy,
    microfrontendBody: microfrontendBodyCopy,
  };
};

const getMicrofrontendFragment = ({ microfrontendName }) => {
  if (microfrontendName) {
    return cloneFragmentRegistry(microfrontendRegist[microfrontendName]);
  }
  return microfrontendRegist;
};

const FragmentMgr = {
  registConfigJson: config => {
    configJson = config;
  },
  getFragmentsWithTag,
  getFragmentsDetailWithTag,
  register,
  registerFragment: register,
  get: getFragment,
  getFragment,
  unmount: unmountFragment,
  unmountFragment,
  resolveTag: resolveTagFragments,
  resolveTagFragments,
  registerMicrofrontendFragments,
  registerMicrofrontend: registerMicrofrontendFragments,
  getMicrofrontendFragment,
  getMicrofrontend: getMicrofrontendFragment,
  unmountFragments,
  renderFragments,
};

export default FragmentMgr;
